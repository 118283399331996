import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import estimate from './estimate';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({ estimate });
export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const store = configureStore({ reducer: persistedReducer });
  const persistor = persistStore(store);

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../store', () =>
      store.replaceReducer(persistReducer(persistConfig, rootReducer))
    );
  }

  return { store, persistor };
};
