import { createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
  LEASE_END_FEE_MIN,
  LEASE_END_FEE_MAX,
  HELPFULNESS,
} from '../constants';

const { actions, reducer } = createSlice({
  name: 'estimate',
  initialState: {
    vehicleMake: 'vw',
    vehicleValue: 0,
    monthlyPayment: 487,
    remainingPayments: 18,
    payoffQuote: 0,
    replacementCost: 2500,
  },
  reducers: {
    setVehicleMake(state, action) {
      state.vehicleMake = action.payload;
    },
    setVehicleValue(state, action) {
      state.vehicleValue = action.payload;
    },
    setMonthlyPayment(state, action) {
      state.monthlyPayment = action.payload;
    },
    setRemainingPayments(state, action) {
      state.remainingPayments = action.payload;
    },
    setPayoffQuote(state, action) {
      state.payoffQuote = action.payload;
    },
    setReplacementCost(state, action) {
      state.replacementCost = action.payload;
    },
  },
});

export enum EstimateState {
  Pending = 'pending',
  CantHelp = 'cant-help',
  CanHelp = 'can-help',
}

export type Estimate =
  | {
      state: EstimateState.Pending;
    }
  | {
      state: EstimateState.CanHelp | EstimateState.CantHelp;
      isAbsolutelyBetter: boolean;
      equity: number;
      monthlyPayment: number;
      adjustedMonthlyPayment: number;
      remainingPayments: number;
      payoffViaLease: number;
      leaseEndFeeMin: number;
      leaseEndFeeMax: number;
      costToRemainMin: number;
      costToRemainMax: number;
      replacementCost: number;
      costToGetOut: number;
    };

export const getIsEstimateReady = (state: RootState) =>
  state.estimate.vehicleValue > 0 && state.estimate.payoffQuote > 0;

export const getEstimate = createSelector(
  (state: RootState) => state.estimate,
  getIsEstimateReady,
  (estimate, isEstimateReady): Estimate => {
    if (!isEstimateReady) return { state: EstimateState.Pending };

    const {
      monthlyPayment,
      remainingPayments,
      replacementCost,
      payoffQuote,
      vehicleValue,
    } = estimate;
    const payoffViaLease = monthlyPayment * remainingPayments;
    const leaseEndFeeMin = LEASE_END_FEE_MIN;
    const leaseEndFeeMax = LEASE_END_FEE_MAX;
    const costToRemainMin = payoffViaLease + leaseEndFeeMin;
    const costToRemainMax = payoffViaLease + leaseEndFeeMax;
    const costToRemainAvg = (costToRemainMin + costToRemainMax) / 2;
    const adjustedMonthlyPayment = costToRemainAvg / remainingPayments;
    const equity = vehicleValue - payoffQuote;
    const costToGetOut = Math.max(0, replacementCost - equity);

    const isAbsolutelyBetter = costToRemainAvg > costToGetOut;
    const isHelpful =
      isAbsolutelyBetter ||
      (costToGetOut - costToRemainAvg) / costToRemainAvg <= HELPFULNESS;

    return {
      state: isHelpful ? EstimateState.CanHelp : EstimateState.CantHelp,
      isAbsolutelyBetter,
      monthlyPayment,
      adjustedMonthlyPayment,
      remainingPayments,
      payoffViaLease,
      leaseEndFeeMin,
      leaseEndFeeMax,
      costToRemainMin,
      costToRemainMax,
      replacementCost,
      equity,
      costToGetOut,
    };
  }
);

export const getVehicleMake = (state: RootState) => state.estimate.vehicleMake;
export const getVehicleValue = (state: RootState) =>
  state.estimate.vehicleValue;
export const getMonthlyPayment = (state: RootState) =>
  state.estimate.monthlyPayment;
export const getRemainingPayments = (state: RootState) =>
  state.estimate.remainingPayments;
export const getPayoffQuote = (state: RootState) => state.estimate.payoffQuote;
export const getReplacementCost = (state: RootState) =>
  state.estimate.replacementCost;

export const {
  setVehicleMake,
  setVehicleValue,
  setMonthlyPayment,
  setRemainingPayments,
  setPayoffQuote,
  setReplacementCost,
} = actions;

export default reducer;
