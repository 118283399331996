export const LEASE_END_FEE_MIN = 1000;
export const LEASE_END_FEE_MAX = 1800;

/**
 * `HELPFULNESS` is a percentage used to determine whether
 * Hamster can be helpful to a customer or not; when calculating
 * the total cost to stay in a lease vs. the total cost to leave
 * a lease and escape, the cost to stay must be at least `HELPFULNESS`
 * percent lower than the cost to leave for the decision to stay
 * to be marked as better. The summary message will change slightly
 * when the cost to leave is slightly higher.
 */
export const HELPFULNESS = 0.15;

export const API_BASE_URL = '/.netlify/functions';
